<template>
  <SeparateInputs
    :inputs-mapping="inputMapping"
    v-bind="{ ...$attrs }"
    v-on="$listeners"
  />
</template>

<script>
import SeparateInputs from 'chimera/all/components/elements/SeparateInputs'

export default {
  name: 'PostalSeparateInputsBE',

  components: {
    SeparateInputs,
  },

  /**
   * @returns {{inputMapping}}
   */
  data() {
    return {
      inputMapping: [
        {
          type: 'tel',
          placeholder: 1,
        },
        {
          type: 'tel',
          placeholder: 2,
        },
        {
          type: 'tel',
          placeholder: 3,
        },
        {
          type: 'tel',
          placeholder: 4,
        },
      ],
    }
  },
}
</script>
